import {HttpExclude, Request, RequestExpose} from '@simpli/serialized-request'
import {ExpansibleCollection} from '@simpli/resource-collection'
import {Dapp} from '@/model/resource/Dapp'
import {CategoryCollection} from '@/model/collection/CategoryCollection'
import {NntTagCollection} from '@/model/collection/NntTagCollection'
import {TokenCollection} from '@/model/collection/TokenCollection'
import {WalletCollection} from '@/model/collection/WalletCollection'
import {BlockchainVersionCollection} from '@/model/collection/BlockchainVersionCollection'
import {BlockchainVersion} from '@/model/resource/BlockchainVersion'
import {$} from '@/facade'

@HttpExclude()
export class DappCollection extends ExpansibleCollection<Dapp> {
  resource?: IDappCollectionResourcesHolder
  requestName: string = 'listDapp'

  @RequestExpose() inActiveDevelopment: boolean | null = true
  @RequestExpose() isDapp: boolean | null = null
  @RequestExpose() idCategoryFk: number[] = []
  @RequestExpose() idWalletFk: number[] = []
  @RequestExpose() idTntTagFk: number[] = []
  @RequestExpose() idTokenFk: number[] = []
  @RequestExpose() idBlockchainVersionFk: number | null = null
  @RequestExpose() private: boolean | null = null
  @RequestExpose() privateCategorySlug: string | null = null
  @RequestExpose() selectedIdDapp: number | null = null
  @RequestExpose() trending: boolean = false
  @RequestExpose() interval: number | null = null
  @RequestExpose() randomSelection: boolean = false

  private blockchainVersionsItems: BlockchainVersion[] = []

  constructor() {
    super(Dapp)
  }

  get dappCategory() {
    return (
      this.resource?.collectionCategory.getManyIds(this.idCategoryFk) ?? null
    )
  }

  set dappCategory(input) {
    this.idCategoryFk = input?.map(({$id}) => $id) ?? []
  }

  get dappWallet() {
    return this.resource?.collectionWallet.getManyIds(this.idWalletFk) ?? null
  }

  set dappWallet(input) {
    this.idWalletFk = input?.map(item => item?.$id) ?? []
  }

  get dappNntTag() {
    return this.resource?.collectionNntTag.getManyIds(this.idTntTagFk) ?? null
  }

  set dappNntTag(input) {
    this.idTntTagFk = input?.map(item => item?.$id) ?? []
  }

  get dappToken() {
    return this.resource?.collectionToken.getManyIds(this.idTokenFk) ?? null
  }

  set dappToken(input) {
    this.idTokenFk = input?.map(item => item?.$id) ?? []
  }

  get dappBlockchainVersion() {
    return (
      this.resource?.collectionBlockchainVersion.getById(
        this.idBlockchainVersionFk
      ) ?? null
    )
  }

  set dappBlockchainVersion(input: BlockchainVersion | null) {
    this.idBlockchainVersionFk = input?.$id || null
  }

  get filterCount(): number {
    return [
      this.idCategoryFk.length,
      this.idWalletFk.length,
      this.idTntTagFk.length,
      this.idTokenFk.length,
      this.idBlockchainVersionFk ? 1 : 0,
      this.search ? 1 : 0,
      this.inActiveDevelopment ? 0 : 1,
      this.isDapp ? 0 : 1,
    ].reduce((sum, value) => (sum += value), 0)
  }

  get isPrivate() {
    return this.privateCategorySlug !== null
  }

  async queryAsPage() {
    if ($.env.VUE_APP_N3_HIDE) {
      await this.populateVersionItems()

      this.dappBlockchainVersion =
        this.blockchainVersionsItems.find(it =>
          it.title?.toLocaleLowerCase()?.includes('legacy')
        ) || null
    }
    return this.listDapp()
  }

  async listRandomDapp() {
    return await Request.get(`/client/dapp/random`, {params: this.params})
      .name(this.requestName)
      .as(this)
      .getResponse()
  }

  async listDapp() {
    return await Request.get(`/client/dapp`, {params: this.params})
      .name(this.requestName)
      .as(this)
      .getResponse()
  }

  async listExportDapp() {
    return await Request.get(`/client/dapp/export`, {params: this.params})
      .name('listExportDapp')
      .as(this)
      .getResponse()
  }

  async populateVersionItems() {
    if (!this.blockchainVersionsItems.length) {
      const {
        data: {items},
      } = await new BlockchainVersionCollection().listBlockchainVersion()
      this.blockchainVersionsItems = items
    }
  }

  showInactiveDapps(active: boolean) {
    active
      ? (this.inActiveDevelopment = null)
      : (this.inActiveDevelopment = true)
  }

  showIsDapp(isDapp: boolean) {
    this.isDapp = isDapp
  }
}

export interface IDappCollectionResourcesHolder {
  collectionCategory: CategoryCollection
  collectionNntTag: NntTagCollection
  collectionToken: TokenCollection
  collectionWallet: WalletCollection
  collectionBlockchainVersion: BlockchainVersionCollection
}
